@font-face {
  font-family: 'Inter';
  src: URL('./assets/fonts/Inter/Inter-Variable.ttf') format('truetype');
}

$theme-colors: (
  "primary": #2EA9C7
);

// Import Bootstrap after declaring $theme-colors
@import "../node_modules/bootstrap/scss/bootstrap";

html, body {
  position: relative;
  height: 100%;
  width: 100%;
  margin: '0';
  padding: '0';
  font-family: 'Inter', sans-serif !important;
  overscroll-behavior: none;
}

#root {
  background-color: '#1E1E1E';
  height: 100%;
  width: 100%;
  margin: '0';
  padding: '0';
  --bs-body-font-family: 'Inter', sans-serif;
}

@keyframes slideFromTop  {
  0%{ 
      opacity: 0.4;
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      -ms-transform: translateY(-8px);
      -o-transform: translateY(-8px);
      transform: translateY(-8px);
  }
  100%{
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
  }
}

.show {
  animation-name: slideFromTop;
  -webkit-animation-name: slideFromTop;
  animation-duration: 180ms;
  -webkit-animation-duration: 180ms;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
