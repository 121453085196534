#return-button {
    position: fixed;
    bottom: 20;
    width: 100%;
    transform: translateY(100%); /* Start off-screen */
    transition: transform 0.3s ease-out;
}

#return-button.visible {
    transform: translateY(0); /* Move into view */
}

.slideRight {
    transition: transform 0.3s ease-in;
    transform: translateX(100%) !important;
  }