#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(100%); /* Start off-screen */
  transition: transform 0.3s ease-out;
}

#footer.visible {
  transform: translateY(0); /* Move into view */
}
